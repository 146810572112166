import SimpleBarReact, { Props as SimpleBarProps } from 'simplebar-react';
import { alpha, styled } from '@material-ui/core/styles';
import { Box, BoxProps } from '@material-ui/core';

const RootStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
}));

// Usamos SimpleBarProps para tipar o componente estilizado, evitando incluir atributos do MUI.
const SimpleBarStyle = styled(SimpleBarReact)<SimpleBarProps>(({ theme }) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[600], 0.48),
    },
    '&.simplebar-visible:before': {
      opacity: 1,
    },
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 10,
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6,
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
}));

export default function Scrollbar({ children, sx, ...other }: BoxProps & SimpleBarProps) {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  if (isMobile) {
    // No mobile usamos o Box do MUI que lida corretamente com a prop sx.
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <RootStyle>
      {/* Aplica o sx no Box que envolve o SimpleBarStyle e, em seguida, repassa para o SimpleBar apenas as props esperadas */}
      <Box sx={sx}>
        <SimpleBarStyle timeout={500} clickOnTrack={false} {...(other as SimpleBarProps)}>
          {children}
        </SimpleBarStyle>
      </Box>
    </RootStyle>
  );
}
