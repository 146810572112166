import { Stack, Typography, Link } from "@mui/material";
import { RootStyle } from "./styles";

const pdfs = [
  {
    text: "Declaração de Práticas de Negócio RFB",
    img: "/static/components/upload.png",
    link: "/pdfs/Declaracao-de-Praticas-de-Negocio-RFB.pdf",
  },
  {
    text: "Declaração de Práticas de Negócio CD",
    img: "/static/components/upload.png",
    link: "/pdfs/Declaracao-de-Praticas-de-Negocio-CD.pdf",
  },
  {
    text: "Declaração de Práticas de Negócios PASSWORD - AC SERPRO",
    img: "/static/components/upload.png",
    link: "/pdfs/Declaracao_de_Praticas_de_Negocios_PASSWORD-AC_SERPRO.pdf",
  },
  {
    text: "Declaração de Práticas de Negócios PASSWORD - AC SOLUTI",
    img: "/static/components/upload.png",
    link: "/pdfs/Declaracao_de_Praticas_de_Negocios_PASSWORD-AC_SOLUTI.pdf",
  },
];

const View = () => {
  return (
    <RootStyle>
      <Stack
        direction="column"
        spacing={1}
        sx={{
          backgroundColor: "rgb(244, 246, 248)",
          borderColor: "rgb(244, 246, 248)",
          borderRadius: 1,
          paddingLeft: "0px",
          width: "100%",
          height: "100%",
          paddingX: 1,
          paddingY: 2,
        }}
      >
        {pdfs.map((item) => (
          <Link
            href={item.link}
            target="_blank"
            sx={{ textDecoration: "none" }}
          >
            <Stack
              direction="row"
              spacing={3}
              alignItems="center"
              justifyContent="start"
              sx={{
                borderRadius: 1,
                backgroundColor: "white",
                width: "100%",
                padding: 1,
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <img
                width={70}
                src="/static/icons/file/file_type_pdf.svg"
                alt="imagem pdf"
              />
              <Typography
                align="center"
                variant="body1"
                color="rgb(33, 43, 54)"
              >
                {item.text}
              </Typography>
            </Stack>
          </Link>
        ))}
      </Stack>
    </RootStyle>
  );
};

export default View;
